.q {
  font-family: "Times New Roman", Times, serif;
  font-size: calc(11px + 2vmin);
}
.s-1 {
  font-size: 1em;
}
.s-2 {
  font-size: 1.05em;
}
