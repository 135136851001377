.release-bio-paragraphs {
  font-size: 0.65em;
}
.smaller-font-temp {
  font-size: 0.75em;
}
.release-page-h4 {
  margin-bottom: 0px !important;
  font-size: 0.8em;
}
.release-page-text-container {
	line-height: 30px;
	margin-bottom: 20px;
	font-size: 1.25em;
}
.primary-artist-release{
	font-size: 2em;
}